/**
 * Created by andrey on 27.04.18.
 */

cleverapps.Availables = {
    LEADER_BOARD: { level: 1.2 },
    DAILY_TASKS: { level: 2.87 },
    INVITE_FRIENDS_ICON: { level: 0.1 },
    CREATE_SHORTCUT: { level: 0.33 },
    JOIN_GROUP: {
        level: 6
    },
    CLANS: {
        level: 8.5,
        source: ["facebook", "instant", "android", "amazon", "microsoft"],
        projectName: ["mergecraft", "wondermerge", "fairy"],
        cleverapps: {
            debugMode: true,
            level: 8.5,
            projectName: ["mergecraft", "wondermerge", "fairy"]
        }
    },
    STICKERS_BOOK: { level: 2.66 }
};

if (cleverapps.config.type === "merge") {
    cleverapps.Availables.DAILY_TASKS = { level: 6 };
    cleverapps.Availables.CREATE_SHORTCUT = { level: 3 };
    cleverapps.Availables.PIXELS = { level: 4.5 };
    cleverapps.Availables.FRIENDBALLOON = {
        level: 4,
        projectName: ["mergecraft", "wondermerge", "hustlemerge"],
        source: ["instant", "discord", "test"]
    };
    cleverapps.Availables.PIXELOFFER = { level: 4.5 };
    cleverapps.Availables.UNITMAGNET = { level: 6.5 };
    cleverapps.Availables.TRADE = { gameLevel: 7 };
}

if (cleverapps.config.type === "tile3") {
    cleverapps.Availables.EXTRA_SLOT = { level: 2.66, projectName: ["zenmatch"] };
}
