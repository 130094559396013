/**
 * Created by olga on 26.12.2024
 */

var BoardTutorial = function () {
    return new TutorialScenario(TutorialScenario.BASIC_TUTORIAL_NAME, {
        listSteps: BoardTutorial.listSteps,

        onStart: function () {
            var active = cleverapps.tutorial.getActive();
            active.highlightedLettersElements = [];

            Game.currentGame.keypad.hideShuffleButton();
        },

        onFinish: function () {
            var active = cleverapps.tutorial.getActive();
            delete active.highlightedLettersElements;
            Game.currentGame.keypad.showShuffleButton();
        },

        isAvailable: function () {
            var game = Game.currentGame;

            if (!game) {
                return false;
            }
            
            return game.introPlaying ? game.levelWithTutorial() && BoardTutorialHelper.listTutorialWords(2).length
                : cleverapps.isKnockoutGame() && !cleverapps.forces.isShown(Forces.KEYPAD.id);
        }
    });
};

BoardTutorial.listSteps = function () {
    var steps = [];

    BoardTutorialHelper.listTutorialWords(3).forEach(function (word, index) {
        var options = {};
        options.word = word;
        options.pathColor = cleverapps.styles.COLORS.PATH_COLOR;

        var scene = cleverapps.scenes.getRunningScene();

        var highlightedLettersElements = cleverapps.tutorial.getActive().highlightedLettersElements;

        Game.currentGame.board.listWordIndexes(word).forEach(function (indexes) {
            highlightedLettersElements.push(scene.boardView.grid.getCell(indexes[0], indexes[1]));
            highlightedLettersElements.push(scene.boardView.grid.getCell(indexes[0], indexes[1], cleverapps.Board.LETTER_LAYER));
        });

        options.highlights = highlightedLettersElements;

        if (index === 0 && !cleverapps.forces.isShown(Forces.KEYPAD.id)) {
            options.force = Forces.KEYPAD;

            if (Game.currentGame.board.gridType === "crossword") {
                options.force.position = { x: { align: "right" }, y: { align: "center", dy: 90 } };
            } else {
                options.importantNodes = highlightedLettersElements.concat(scene.currentView);
            }
        }

        steps.push(BoardTutorialHelper.getDiscoverWordStep(options));
    });

    return steps;
};
