/**
 * Created by slava on 23.03.17.
 */

var HoseScene = MainScene.extend({
    ctor: function (options) {
        this._super(options);

        var currentLevel = cleverapps.meta.selectedLocationId() + cleverapps.meta.getSelectedLocation().getProgress() / HoseLocation.LEVELS_PER_LOCATION;
        if (cleverapps.meta.getSelectedLocation().isCompleted() && !cleverapps.meta.isCompleted()) {
            currentLevel -= 1;
        } else if (cleverapps.meta.isCompleted()) {
            currentLevel += 0.4;
        }

        this.pagingScroll = new PagingScroll(cleverapps.meta.listAvailableLocations().length);
        this.pagingScroll.scrollToLevel(0, currentLevel);
    },

    onSceneLoaded: function () {
        this._super();

        if (Game.currentGame) {
            cleverapps.throwAsync("Game.currentGame not cleared");
        }

        var episodesView = new PagingScrollView(this.pagingScroll);
        episodesView.setLocalZOrder(-1);
        this.addChild(episodesView);

        if (typeof PlayButtonView !== "undefined") {
            var playButton = new PlayButtonView();
            playButton.addFinger();
            this.addChild(playButton);

            cleverapps.focusManager.registerControl("play_button", new HidingNode(playButton, HidingNode.DIR.NONE));
        }
    },

    getBackgroundStyles: function () {
        var bundleName = cleverapps.meta.getSelectedLocation().getBackgroundBundle();
        if (bundleName) {
            return {
                bundle: bundleName,
                backgroundId: "background"
            };
        }
    },

    listBundles: function () {
        var res = [];

        var nonBlockingUrls = [];

        this.pagingScroll.listVisiblePages().forEach(function (page) {
            var externalBundleId = page.getResourcesBundleId();
            if (bundles[externalBundleId]) {
                if (connector.info.isNative) {
                    nonBlockingUrls = nonBlockingUrls.concat(Object.values(bundles[externalBundleId].urls));
                } else {
                    res.push(externalBundleId);
                }
            }

            if (page.isComingSoon()) {
                res.push("episode_coming_soon");
            }
        });

        nonBlockingUrls.forEach(function (url) {
            cc.loader.load(url);
        });

        var backgroundBundle = cleverapps.meta.getSelectedLocation().getBackgroundBundle();
        if (backgroundBundle) {
            res.push(backgroundBundle);
        }

        if (cleverapps.config.rpg) {
            res = res.concat(["episodes_resources"]);
        }

        return res;
    },

    gotoNextLocation: function (callback, toEpisode) {
        this.pagingScroll.gotoNextPage(callback, toEpisode);
    }
});

cleverapps.styles.HoseScene = {};
