/**
 * Created by Vladislav on 18.09.2024.
 */

cleverapps.InitByType["board"] = function () {
    levels.gameHints = new levels.GameHints();
    levels.cookieJar = new cleverapps.CookieJar();

    if (cleverapps.config.subtype === "stacks") {
        cleverapps.tutorial.register(StacksTutorial());
        cleverapps.tutorial.register(TrickyStacksTutorial());
    } else {
        cleverapps.tutorial.register(BoardTutorial());
    }
};