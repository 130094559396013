/**
 * Created by olga on 26.12.2024
 */

var StacksTutorial = function () {
    return new TutorialScenario(TutorialScenario.BASIC_TUTORIAL_NAME, {
        listSteps: StacksTutorial.listSteps,
        isAvailable: StacksTutorial.isAvailable
    });
};

StacksTutorial.listSteps = function () {
    var steps = [];

    var amount = BoardTutorialHelper.listTutorialWords(3).length;
    for (var i = 0; i < amount; i++) {
        var options = {};
        options.word = function () {
            return Game.currentGame.keypad.getExistedWords()[0];
        };

        if (i === 0 && !cleverapps.forces.isShown(Forces.KEYPAD.id)) {
            options.force = Forces.KEYPAD;
            options.force.position = MinimalDialogue.POSITIONS.CENTER_LEFT;
        }

        var scene = cleverapps.scenes.getRunningScene();
        options.highlights = [scene.boardView];

        steps.push(BoardTutorialHelper.getDiscoverWordStep(options));
    }
    return steps;
};

StacksTutorial.isAvailable = function () {
    var game = Game.currentGame;
    return game && game.introPlaying && game.levelWithTutorial();
};
